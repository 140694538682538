<script setup>
import {ref} from "vue";

const selectedtipe = ref();
const selectedpic = ref();

</script>

<template>
  <b-card>
    <b-form v-on:submit.prevent>
      <!--
      <b-row>
        <b-col cols="4">
          <b-form-group label="Customer Category" label-for="customercategory">
            <b-form-select
              id="customercategory"
              v-model="form.selectedCategory"
              :options="form.optionsCategory"
              required
            />
            Selected: <strong>{{ form.selectedCategory }}</strong> 
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col cols="4">
          <b-form-group label="Nama" label-for="Nama">
            <b-form-input
              id="name"
              placeholder="Nama"
              v-model="form.name"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Phone" label-for="Phone">
            <b-form-input
              id="business_phone"
              placeholder="Phone"
              v-model="form.business_phone"
              type="number"
              required
            />
          </b-form-group>
        </b-col>
        <!--
        <b-col cols="4">
          <b-form-group label="Fax" label-for="fax">
            <b-form-input
              id="fax"
              placeholder="Fax"
              v-model="form.fax"
              type="number"
              required
            />
          </b-form-group>
        </b-col>-->
        <b-col cols="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input
              id="email"
              placeholder="Email"
              v-model="form.email"
              required
            />
          </b-form-group>
        </b-col>
        <!--
        <b-col cols="4">
          <b-form-group label="Website" label-for="website">
            <b-form-input
              id="website"
              placeholder="Website"
              v-model="form.website"
              required
            />
          </b-form-group>
        </b-col>-->
      </b-row>
      <hr />
      <!--
      <b-row>
        <b-col cols="12">
          <b-form-group label="Address" label-for="address">
            <b-form-textarea
              id="address"
              placeholder="Address"
              v-model="form.address"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Village" label-for="village">
            <b-form-input
              id="village"
              placeholder="Village"
              v-model="form.village"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="District" label-for="district">
            <b-form-input
              id="district"
              placeholder="District"
              v-model="form.district"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="City" label-for="city">
            <b-form-input
              id="city"
              placeholder="City"
              v-model="form.city"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Province" label-for="province">
            <b-form-input
              id="province"
              placeholder="Province"
              v-model="form.province"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Country" label-for="country">
            <b-form-input
              id="country"
              placeholder="Country"
              v-model="form.country"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Postal Code" label-for="postal_code">
            <b-form-input
              id="postal_code"
              placeholder="Postal Code"
              v-model="form.postal_code"
              type="number"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />-->
      <b-row>
         <b-col cols="4">
              <b-form-group label="Tipe Pelanggan" label-for="tipepelanggan">
                <b-form-select
                  id="selectedtipe"
                  v-model="selectedtipe"
                  :options="optiontipe"
                  required
                />
                <!-- Selected: <strong>{{ filter.selectedCategory }}</strong> -->
              </b-form-group>
            </b-col>

        <div v-if ="selectedtipe===3">
        <b-col cols="12">
          <b-form-group label="Person In Charge" label-for="pic">
            <b-form-select
              id="selectedpic"
                  v-model="selectedpic"
                  :options="optionpic"

            />
          </b-form-group>
        </b-col>
        </div>
        <!--
        <b-col cols="4">
          <b-form-group label="Phone" label-for="personal_phone">
            <b-form-input
              id="personal_phone"
              placeholder="Phone"
              v-model="form.personal_phone"
              type="number"
              required
            />
          </b-form-group>
        </b-col>-->
      </b-row>
      <b-row>
        <b-col md="12" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="addCustomerPlusValidation()"
          >
            Submit
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    ToastificationContent,
    BFormTextarea,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedtipe: "2",
      optiontipe: [
        {
            value: 2,
            text: "PIC",
          },
          {
            value: 3,
            text: "Driver",
          },
        ],
      selectedpic: "",
      optionpic: [],

      form: {
        name: "",
        
        
        pic: "",
        email: "",
        business_phone: "",
        personal_phone: "",
        fax: "",
        website: "",
        address: "",
        village: "",
        district: "",
        city: "",
        province: "",
        country: "",
        postal_code: "",
      },
      errors: "",
      errMessage: "",
    };
  },
  created() {
    this.getPICData();
  },
  methods: {
    /*
    getCategories() {
      this.$http.get("sales/customer/categories/all").then((response) => {
        // console.log(response.data.data)
        this.form.optionsCategory.push({
          value: null,
          text: "Select 1 Vendor Category",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          // console.log(datas[1].children.length === 0)
          if (datas[i].children.length === 0) {
            var $data = {
              value: datas[i].id,
              text: datas[i].name,
            };
            // console.log($data)
            this.form.optionsCategory.push($data);
          } else {
            var $dataParent = {
              value: datas[i].id,
              text: datas[i].name,
              disabled: true,
            };
            this.form.optionsCategory.push($dataParent);

            var j;
            var datas2 = datas[i].children;
            // console.log(datas2[i].name)
            for (j = 0; j < datas2.length; j++) {
              var $dataChildren = {
                value: datas2[j].id,
                text: datas[i].name + " - " + datas2[j].name,
              };
              this.form.optionsCategory.push($dataChildren);
            }
          }
        }
        // console.log(this.form.optionsCategory)
      });
    },*/
    getPICData() {
      this.$http.get("customers?type=2&list=1&sortby=name").then((response) => {
        var datas = response.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name +" ( "+ datas[i].company.name+" )",
          };
          this.optionpic.push($data);
        }
      });
    },
    addCustomerPlusValidation() {
      if (this.form.name === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Nama harus di isi",
            variant: "warning",
          },
        });
      }
      else if (this.form.business_phone === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "No Telp harus di isi",
            variant: "warning",
          },
        });
      } else if (this.form.email === "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Email harus di isi",
            variant: "warning",
          },
        });
      }else {
        this.$http
          .post("register", {
            name: this.form.name,
            type: this.selectedtipe,
            ref_id: this.selectedpic,
            email: this.form.email,
            phone: this.form.business_phone,
            verified: 1,
            status: 1,
          })
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: "Success tambah pelanggan",
                variant: "success",
              },
            });
            location.href = "/customer/add";
            // console.log(response.data.data)
          })
          .catch((errors) => {
            this.errMessage = errors.response.data.message;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "BellIcon",
                text: this.errMessage,
                variant: "warning",
              },
            });
            console.log(errors.response);
          });
      }
    },
  },
};


</script>

<style>
#customercategory option:disabled {
  font-weight: bolder;
  /* color: darkgray; */
  /* text-align: center; */
  background-color: #ebebeb;
}
#customercategory option:not([disabled]) {
  margin-left: 40px !important;
  transform: scaleX(200px);
}
</style>